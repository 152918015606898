import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import PageTitle from 'components/PageTitle'

const Index = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'stack.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.stack' })}
          isBlogPost={false}
          ogTitle={formatMessage({ id: 'stack.title' })}
          ogUrl={formatMessage({ id: 'meta.url.stack' })}
          ogDescription={formatMessage({ id: 'meta.description' })}
          ogKeywords={formatMessage({ id: 'meta.keywords' })}
          ogLocale={locale}
          twitterTitle={formatMessage({ id: 'stack.title' })}
          twitterDescription={formatMessage({ id: 'meta.description' })}
          twitterCreator={'Mihir Pipermitwala'}
          siteName={'Mihir Pipermitwala'}
        />
        <section className='section is-size-5-desktop is-size-6-touch'>
          <div className='container mt-1 has-text-centered'>
            <div className='is-vertical is-7'>
              <article className='is-child'>
                <div className='content'>
                  <PageTitle pageTitle='Thank you!' />
                  <h5 className='subtitle has-text-centered has-text-light is-5'>I will get in touch soon</h5>
                  {/* <h4>For urgent queries find me online:</h4>
                              <ul>
                                <li>
                                  <a href='https://instagram.com/mihirpipermitwala'>@mihirpipermitwala on Instagram</a>
                                </li>
                              </ul>
                              <p>
                                Feel free to send me an email directly. If you have a technical question, you may want
                                to ask it on <a href='http://stackoverflow.com/'>StackOverflow</a> and just email or
                                tweet me the link.
                              </p> */}
                </div>
              </article>
            </div>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
)

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
